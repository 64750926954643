import React,{useState,useEffect} from "react";
import {useLocation,useParams,Link} from "react-router-dom"
import Project from "../Components/ProjectDirection";
import { projects } from "../Details";

function Projects() {
  const [dataShowProject,setDataShowProject]=useState([])
  const [typeProject,setTypeProject]=useState({})
  let {pathname} =useLocation()
  let params=useParams()
  useEffect(function(){
    //console.log(pathname,params.project)
    const dta=projects.filter(function(project){
      if(!typeProject[project.type]){
        typeProject[project.type]=0
      }
      else{
        typeProject[project.type]=typeProject[project.type]+1
      }
      if(params.project==project.type){

        return project
      }
      else if(params.project="all"){
        return project
      }
    })
    setDataShowProject(dta);
  },[])

  return (
    <main className="container mx-auto max-width pt-10 mb-20">
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Projects
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10">
          {React.Children.toArray(
            dataShowProject.map(
              ({ title, image, description, techstack, previewLink, githubLink }) => (
                <Project
                  title={title}
                  image={image}
                  description={description}
                  techstack={techstack}
                  previewLink={previewLink}
                  githubLink={githubLink}
                />
              )
            )
          )}
        </div>
      </section>
    </main>
  );
}

export default Projects;
